import React, { FunctionComponent, useEffect, useState } from 'react'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import axios from '@services/axiosInterceptorInstance'
import { APP_VERSION, NEXT_GET_PAGE_PREVIEW_CONTENT } from '../constants'
import { isMobileOnly } from 'react-device-detect'
import TopStripActiveOffers from './TopStripActiveOffers'
import { useUI } from '@components/ui'

interface IProps {
  isMobile?: boolean
}

declare const window: any

export const TopStrip: FunctionComponent<IProps> = ({ isMobile }) => {
  const [activeTopStripOffers, setActiveTopStripOffers] = useState<Array<any>>(
    []
  )
  const { setAppVersionData } = useUI()

  const handleRecordGA4Event = () => {
    recordGA4Event(window, GA_EVENT.TOP_STRIP, {
      action: 'app_download',
    })
  }

  const getTopStripOffers = async () => {
    const { data }: any = await axios.get(NEXT_GET_PAGE_PREVIEW_CONTENT, {
      params: {
        slug: APP_VERSION,
        workingVersion: process.env.NODE_ENV === 'production' ? true : true, // TRUE for preview, FALSE for prod.
        channel: 'Web',
      },
    })
    setAppVersionData(data)
    if (isMobileOnly) {
      if (data?.offerstrip) {
        setActiveTopStripOffers([...data?.offerstrip] || [])
      }
    }
  }

  useEffect(() => {
    getTopStripOffers()
  }, [])

  return (
    <div className="top-strip-active-offers-wrapper">
      <TopStripActiveOffers
        activeTopStripOffers={activeTopStripOffers}
        handleRecordGA4Event={handleRecordGA4Event}
      />
    </div>
  )
}
